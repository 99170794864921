import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import pic from "../assets/images/level-of-detail.png";
import { logAnalytics } from "../firebase";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function LevelOfDetailPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.LEVELOFDETAIL}>
      <Seo title="Level of Detail | Robotic Imaging" />

      <header className="site__header"></header>

      <main className="content">
        <img src={pic} className="img-sm" alt="" />
        <div className="project-delivery">
          <h3>Level 200 Modeling:</h3>
          <ul>
            <li>
              <span className="lidar__listitem__header">
                {'Architectural - '}
              </span>
              <span>{'LOD 200 will show thickness of walls, window sizes and shapes, and decorative interior/exterior elements will be represented as squared off “blocks” with no measurable detail (for example, crown moulding will not have any curves and will be represented as a solid rectangle at the widest part of the moulding'}</span>
            </li>
            
            <li>
              <span className="lidar__listitem__header">
                {'Structural - '}
              </span>
              <span>{'LOD 200 will show major structural elements such as beam, column, and girders. Braces, bridging and connection plates will not be shown.'}</span>
              
              <ul>
                <li >
                  <span className="lidar__sublistitem__header">{'Interior: '}</span>
                  <span>{'Walls (overall thickness), Curtain Walls (overall spacing of mullions), Doors/Windows (overall sizes), Columns (overall size and location), Stairs/Ramps (size and tread spacing), Railings (Detailed) Floors (overall thickness if accessible on both size), Ceilings (height identified), Ceiling/Wall Mounted Fixtures (using native Revit components not representative), Utility Equipment (generic “block” showing shape and size), Stationary Casework (generic “block” showing shape and size),'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Exterior: '}</span>
                  <span>{'Walls (overall thickness), Curtain Walls (overall spacing of mullions), Doors/Windows (overall sizes), Columns (overall size and location), Stairs/Ramps (size and tread spacing), Wall Mounted Fixtures (using native Revit components not representative), Utility Equipment (generic “block” showing shape and size), Representative Grade Line, Roof (if pitched), Bollards, Signage (overall size)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Above Ceiling: '}</span>
                  <span>{'Floors (overall composition of floor structure represented as a floor with bottom of lowest structural member identified), Ducts (main duct trunk line)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Roof: '}</span>
                  <span>{'Roof (location and general slope), Parapet Walls, Utility Equipment (generic “block” showing shape and size)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Site: '}</span>
                  <span>{'General Site Bounds'}</span>
                </li>
              </ul>
            </li>
          </ul>

          <h3>Level 300 Modeling:</h3>
          <ul>
            <li>
              {'Parts necessary for coordination of the element with nearby or attached elements are modeled. These include items like supports and connections. The quantity size, shape, location and orientation of the elements. Model can be directly measured from without referring to non-modeled information such as dimension call-outs.'}
            </li>
            <li>
              <span className="lidar__listitem__header">
                {'Architectural - '}
              </span>
              <span>{'LOD 300 will show recesses around windows, more blocking on decorative elements.'}</span>
            </li>
            <li>
              <span className="lidar__listitem__header">
                {'Structural - '}
              </span>
              <span>{'LOD 300 will include smaller structural members like column braces and bridging.'}</span>
              <ul>
                <li>
                  <span className="lidar__sublistitem__header">{'Interior: '}</span>
                  <span>{' Walls (overall thickness and materials identified), Curtain Walls (overall spacing of mullions), Doors/Windows (overall sizes with accurate frame dimensions), Columns (overall size and location), Stairs/Ramps/Supports (size and tread spacing), Railings (enhanced detail), Floors/Floor Finish (overall thickness if accessible on both size), Ceilings (height and type identified), Ceiling/Wall Mounted Fixtures (accurately represented), Utility Equipment (detailed showing accurate footprint), Stationary Casework (detailed showing accurate footprint)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Exterior: '}</span>
                  <span>{'Walls (overall thickness and materials identified), Curtain Walls (overall spacing of mullions), Doors/Windows (overall sizes with accurate frame dimensions), Columns (overall size and location), Stairs/Ramps (size and tread spacing), Railings, Wall Mounted Fixtures (accurately represented), Utility Equipment (detailed showing accurate footprint), Representative Grade Line, Roof (if pitched, materials identified), Bollards, Gutters, Downspouts, Signage (general size and text represented)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Above Ceiling: '}</span>
                  <span>{"Deck Above, Structural Beams, Structural Joists, Ducts (main runs, transitions and branch runs), Pipes (>4”) (main runs, transitions and branch runs), Conduit (>4”) (main runs, transitions and branch runs)"}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Roof: '}</span>
                  <span>{'Roof (detailed slope), Parapet Walls, Utility Equipment (detailed showing accurate footprint), Roof Openings/Penetrations'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Site: '}</span>
                  <span>{'General Topography (elevations identified), Landscaping (landscaping type identified), Site Features (lights, parking spaces, driveways, utility equipment, hardscaping, etc.)'}</span>
                </li>
              </ul>
            </li>
          </ul>

          <h3>Level 350 Modeling:</h3>
          <ul>
            <li>
              <span className="lidar__listitem__header">
                {'Architectural - '}
              </span>
              <span>{'LOD 350 will show in detail the existing shapes of the moulding, layers will be represented in the wall components, and items around openings like brick soldiers will be modeled.'}</span>
            </li>
            <li>
              <span className="lidar__listitem__header">
                {'Structural - '}
              </span>
              <span>{'LOD 350 will include base plate connections (no hardware).'}</span>
              
              <ul>
                <li>
                  <span className="lidar__sublistitem__header">{'Interior: '}</span> 
                  <span>{'Walls/Trim (overall thickness and detailed materials identified), Curtain Walls (overall spacing of mullions and connections), Doors/Windows/Trim (overall sizes with accurate frame dimensions including enhanced detail), Columns/Trim (overall size and location including enhanced detail), Stairs/Ramps/Supports/Trim (size and tread spacing including enhanced detail) Railings (enhanced detail, Floor/Floor Finish (overall thickness if accessible on both size), Ceilings (height and type identified), Ceiling/Wall Mounted Fixtures (accurately represented with enhanced detail), Utility Equipment (enhanced detail with connections, attachments, etc.), Stationary Casework (enhanced detail)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Exterior: '}</span>
                  <span>{'Walls/Trim (overall thickness and detailed materials identified), Curtain Walls (overall spacing of mullions and connections), Doors/Windows/Trim (overall sizes with accurate frame dimensions including enhanced detail), Columns/Trim (overall size and location including enhanced detail), Stairs/Ramps/Supports/Trim (size and tread spacing including enhanced detail), Utility Equipment (detailed showing accurate footprint), Representative Grade Line, Roof (if pitched, materials identified), Bollards, Gutters, Downspouts, Signage (general size and text represented), Utility Equipment (enhanced detail with connections, attachments, etc.), Representative Grade Line, Roof (if pitched, materials identified with enhanced detail), Bollards, Gutters, Downspouts, Signage (detailed size and text represented)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Above Ceiling: '}</span>
                  <span>{'Deck Above, Structural Beams, Structural Joists, Accurate Structural Bracing, Structural Connections (plates, bolts, etc.), Ducts (main runs, transitions, branch runs, supports), Pipes (2”) (main runs, transitions, branch runs, supports, connections), Conduit (>2”) (main runs, transitions, branch runs, supports, connections)'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Roof: '}</span>
                  <span>{'Roof (enhanced detail on slope, material identified), Parapet Walls, Utility Equipment Utility Equipment (enhanced detail with connections, attachments, etc.), Roof Openings/Penetrations (enhanced detail), Pipes, Conduit'}</span>
                </li>
                <li>
                  <span className="lidar__sublistitem__header">{'Site: '}</span>
                  <span>{'Detailed Topography (elevations and topography lines  identified), Landscaping (landscaping type and vegetation identified), Detailed Site Features (lights, parking spaces, driveways, utility equipment, hardscaping, etc.)'}</span>
                </li>
              </ul>

            </li>
          </ul>

        </div>
        <Link
          className="btn btn--center"
          to="/rfp"
          onClick={() => logAnalytics("clicked_receive_quote")}
        >
          Digitize Your Project
        </Link>
      </main>
    </SiteWrapper>
  );
}
